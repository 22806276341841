import {
  FaMedium,
  FaGithub,
  // FaLinkedin,
  FaEnvelope,
  FaFigma,
  FaBehance,
  FaDiscord,
} from "react-icons/fa";

export const links = [
  {
    index: 0,
    title: "Find me on Github",
    href: "https://github.com/mosesmawuru",
    icon: <FaGithub />,
  },
  // {
  //   index: 1,
  //   title: "Find me on LinkedIn",
  //   href: "https://www.linkedin.com/in/yan-ying-liao/",
  //   icon: <FaLinkedin />,
  // },
  // {
  //   index: 2,
  //   title: "Contact me via email",
  //   href: "mailto:moses.m.0329@gmail.com",
  //   icon: <FaEnvelope />,
  // },
  {
    index: 3,
    title: "Find me on Medium",
    href: "https://medium.com/@mosesmawuru",
    icon: <FaMedium />,
  },
  {
    index: 4,
    title: "Find me on Figma",
    href: "https://www.figma.com/@moses_m",
    icon: <FaFigma />,
  },
  {
    index: 5,
    title: "Find me on Behance",
    href: "https://www.behance.net/moses_m",
    icon: <FaBehance />,
  },
];

export const contacts = [
  {
    index: 0,
    title: "Contact me via discord",
    href: "https://discordapp.com/users/1117375516383662122",
    icon: <FaDiscord />,
  },
  {
    index: 1,
    title: "Contact me via email",
    href: "mailto:moses.m.0329@gmail.com",
    icon: <FaEnvelope />,
  },
];
